<template>
  <div id="reset-password" class="main-layout">
    <div id="login-wrapper">
      <div v-bind:key="resetKey" id="reset-window">
        <h1>Enter your new password</h1>
        <label for="password" class="label">Password</label>
        <div class="form-error" v-if="errors.password">
          <div class="error-message">{{ errors.password }}</div>
          <div class="error-bubble"><img src="/images/error-bubble.png" /></div>
        </div>
        <input
          type="password"
          name="password"
          v-model="password"
          :class="fieldClass"
        />
        <button class="wide" @mousedown="resetPassword">Set password</button>
      </div>
    </div>

    <main-layout />
  </div>
</template>
<script>
import { resetPassword } from "../store";
import MainLayout from "../components/MainLayout";

export default {
  components: { MainLayout },
  data: function () {
    return {
      password: null,
      errors: {},
      resetKey: 1,
      fieldClass: "",
    };
  },
  methods: {
    resetPassword() {
      this.fieldClass = "";
      resetPassword(
        this.password,
        this.$route.query.token,
        this.$route.query.tokenId
      )
        .then(() => {
          this.$store.commit("setStep", "login");
          this.state = "login";
          this.$store.commit("setCurrentPage", "/StartPage");
          try {
            this.$router.push({
              path: "/StartPage",
            });
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e) => {
          const error = e.toString().replace(/^.*\): ([^(]+)\(.*$/g, "$1");
          this.errors.password = error;
          this.fieldClass = "error";
          this.resetKey = this.resetKey + 1;
        });
    },
  },
};
</script>
<style lang="scss">
#login-wrapper {
  height: 540px;
  padding-top: 168px;

  .label {
    margin: 10px 10px;
    color: #2b4d37;
  }
  input {
    border: 0;
    display: block;
    width: 100%;
    line-height: 58px;
    background: #fff;
    border: 1.5px solid;
    border-color: #2b4d37;
    box-sizing: border-box;
    border-radius: 10px;
  }
}

#reset-window {
  width: 388px;
  height: 500px;
  margin: 0 auto;
  padding: 0 40px;
  font-size: 18px;
  line-height: 24px;

  button {
    margin-top: 20px;
  }
}
</style>
